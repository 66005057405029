import React, { useEffect } from 'react';

const appendInput = (form, key, value) => {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = value;
    form.appendChild(input);
}

const PostRedirectAltyn = ({ action, MERCH_3D_TERM_URL, creq, threeDSSessionData }) => {
  console.log({ action, MERCH_3D_TERM_URL, creq, threeDSSessionData});

  useEffect(() => {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = action;
    form.style.display = 'none';

    appendInput(form, 'creq', creq);
    appendInput(form, 'threeDSSessionData', threeDSSessionData);
    appendInput(form, 'MERCH_3D_TERM_URL', MERCH_3D_TERM_URL);

    document.body.appendChild(form);
    form.submit();

    return () => {
      document.body.removeChild(form);
    };
  }, [ action, MERCH_3D_TERM_URL, creq, threeDSSessionData ]);

  return null;
};

export default PostRedirectAltyn;