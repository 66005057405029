import React, { useEffect, useRef, Suspense, useState } from 'react';
import { getTransaction } from 'Api';
import Spinner from 'Components/Spinner/Spinner';
import PostRedirect from 'Components/PostRedirect/PostRedirect';
import PostRedirectHalyk from 'Components/PostRedirectHalyk/PostRedirectHalyk';
import PostRedirectAltyn from 'Components/PostRedirectAltyn/PostRedirectAltyn';

function App() {
  const [state, setState] = useState({
    transaction: null,
    gatewayID: null,
    status: null
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const transactionID = params.get('id');

    if (!transactionID) {
      setState(prevState => ({ ...prevState, status: -1 }));
      return;
    }

    const get3DSInfo = async () => {
      try {

        const response = await getTransaction(transactionID);

        setState({
          transaction: response,
          gatewayID: response.gateway_id,
          status: response.status,
          data: response.tds_data
        });

      } catch (error) {
        setState(prevState => ({ ...prevState, status: -1 }));
      }
    };

    // чуть-чуть посмотрим на спиннер 👀
    // setTimeout(() => get3DSInfo(), 2000);
    get3DSInfo();
  }, []);

  // Smartpay - 4 или Монета - 13
  if (state.gatewayID === 4 || state.gatewayID === 13) {
    window.location.href = state.transaction.tds_data.redirect_url;
    return null;
  }

  // Евраз
  if (state.gatewayID === 5 || state.gatewayID === 6 || state.gatewayID === 7) {
    const { gatewayID, Action, creq, threeDSSessionData } = state.data;
    console.log(gatewayID, Action, creq, threeDSSessionData);
    return <PostRedirect url={Action} creq={creq} threeDSSessionData={threeDSSessionData} />
  }

  // Халык
  if (state.gatewayID === 8) {
    const { gatewayID, action, TermUrl, PaReq, MD } = state.data;
    console.log(gatewayID, action, TermUrl, PaReq, MD);
    return <PostRedirectHalyk action={action} PaReq={PaReq} MD={MD} TermUrl={TermUrl} />
  }

  // Алтын
  if (state.gatewayID === 10) {
    const { gatewayID, action, MERCH_3D_TERM_URL, creq, threeDSSessionData } = state.data;
    console.log(gatewayID, action, MERCH_3D_TERM_URL, creq, threeDSSessionData);
    return <PostRedirectAltyn action={action} creq={creq} threeDSSessionData={threeDSSessionData} MERCH_3D_TERM_URL={MERCH_3D_TERM_URL} />
  }

  return <Spinner />;
}

export default App;
