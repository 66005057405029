import axios from 'axios';
import { BASE_URL } from "./config";

export const getTransaction = async id => {
    const url = `${BASE_URL}/core/v1/public/3ds?id=${id}`;
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};